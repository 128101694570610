import React from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, Card, CardContent, CardHeader, Grid, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Stack, TextField, Typography } from '@mui/material';
import { names, uniqueNamesGenerator } from 'unique-names-generator';

const members = Array(5).fill({}).map(() => {
  const name = uniqueNamesGenerator({ dictionaries: [names] });
  return { name, mail: `${name.toLowerCase()}@taskloop.fr` }
});

function ProductSettingsPage() {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} alignContent='flex-start'>
      <Grid item xs={12}>
        <Typography variant='h5'>{t('Settings')} </Typography>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardHeader title={t('Runtime')} />
          <CardContent>
            <Stack spacing={2}>
              <TextField fullWidth type='text' label='runtime' value={'NODE RED'} />
              <TextField fullWidth type='text' label='repository' value={'https://<token>@github.com/taskloop-project.git'} />
              <TextField fullWidth type='text' label='path' value={'scripts/taskloop'} />
            </Stack>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardHeader title={t('API Keys')} />
          <CardContent>

          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardHeader title={t('Members')} />
          <CardContent>
            <List sx={{ width: '100%' }}>
              {members.map(({ name, mail }) => {
                const labelId = `list-secondary-label-${name}`;
                return (
                  <ListItem
                    key={mail}
                  >
                    <ListItemButton>
                      <ListItemAvatar>
                        <Avatar>{name[0]}</Avatar>
                      </ListItemAvatar>
                      <ListItemText id={labelId} primary={mail} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </CardContent>
        </Card>
      </Grid>
    </Grid >
  )
}

export default ProductSettingsPage;
