import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, List, ListItem, ListItemButton, Stack, ListItemText, Chip, Card, CardContent, CardHeader, } from '@mui/material';
const { uniqueNamesGenerator, colors, countries } = require('unique-names-generator');

const TYPE = ['FIRMWARE', 'SCRIPTS', 'FILE'];
const ASSETS = Array(8).fill({}).map(() => ({ name: uniqueNamesGenerator({ dictionaries: [colors, countries] }), version: '0.1.3', date: new Date(), type: TYPE[Math.round(Math.random() * 2)] }));


function AssetsPage() {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} alignContent='flex-start'>
      <Grid item xs={12}>
        <Card>
          <CardHeader title={t('Assets')}/>
          <CardContent>
            <List dense sx={{ width: '100%' }}>
              {ASSETS.map(({ name, version, type }) => {
                return (
                  <ListItem disablePadding key={name} >
                    <ListItemButton>
                      <Stack spacing={5} direction='row' alignItems='center'>
                        <ListItemText primary={name} secondary={version} />
                        <Chip variant='outlined' label={type} />
                      </Stack>
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </CardContent>
        </Card>

      </Grid>
    </Grid>
  )
}

export default AssetsPage;
