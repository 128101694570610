import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, List, ListItem, ListItemButton, Stack, ListItemText, Chip, Card, CardHeader, CardContent, } from '@mui/material';
import cuid from '@paralleldrive/cuid2';

const generateId = cuid.init({ length: 8 });
const STATUSES = ['OFFLINE', 'ONLINE'];
const DEVICES = Array(8).fill({}).map(() => ({ sn: generateId().toUpperCase(), date: new Date(), status: STATUSES[Math.round(Math.random())] }));


function TestbenchsPage() {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} alignContent='flex-start'>
      <Grid item xs={12}>
        <Card>
          <CardHeader title={t('Test Benchs')} />
          <CardContent>
            <List dense sx={{ width: '100%' }}>
              {DEVICES.map(({ sn, status, date }) => {
                return (
                  <ListItem disablePadding key={sn} >
                    <ListItemButton>
                      <Stack spacing={5} direction='row' alignItems='center'>
                        <ListItemText primary={sn} secondary={date.toISOString()} />
                        <Chip color={status === 'ONLINE' ? 'secondary' : undefined} label={status} />
                      </Stack>
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default TestbenchsPage;
