import React from 'react';
import Sidebar, { SidebarElement } from '../misc/Sidebar';
import { useTranslation } from 'react-i18next';

import BackIcon from '@mui/icons-material/ChevronLeft';
import HomeIcon from '@mui/icons-material/Home';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';

import { Divider, List, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

function ProductsSidebar({ pathname }) {
  const { t } = useTranslation();

  const { product_id, organization_id } = useParams();

  return (
    <Sidebar>
      <List>
        <SidebarElement href={`${organization_id}/app`} icon={<BackIcon />} text={t('Home')} />
        <Divider />
        <SidebarElement href={`${pathname}`} icon={<HomeIcon />} text={<Typography fontWeight={800} >{product_id}</Typography>} />
        <SidebarElement href={`${pathname}/tasks`} icon={<AccountTreeIcon />} text={t('Tasks')} />
        <SidebarElement href={`${pathname}/devices`} icon={<PeopleIcon />} text={t('Devices')} />
        <SidebarElement href={`${pathname}/benchs`} icon={<SettingsIcon />} text={t('Test Benchs')} />
        <SidebarElement href={`${pathname}/assets`} icon={<SettingsIcon />} text={t('Assets')} />
        <SidebarElement href={`${pathname}/settings`} icon={<SettingsIcon />} text={t('Settings')} />
      </List>
      <Divider />
    </Sidebar>
  )
}

export default ProductsSidebar;
