import { amber, purple } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import { useMemo } from 'react';

export const useDefaultTheme = (mode) => {
    const theme = useMemo(() =>
        createTheme({
            palette: {
                mode,
                primary: amber,
                secondary: purple,
            },
        }), [mode])
    return theme;
}