import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, ListItem, List, ListItemButton, ListItemText, Stack, Chip, Card, CardHeader, CardContent, } from '@mui/material';
import cuid from '@paralleldrive/cuid2';

const generateId = cuid.init({ length: 12 });
const STATUSES = ['PRODUCED', 'LIVE', 'MAINTENANCE', 'WITHDRAWN'];
const DEVICES = Array(100).fill({}).map(() => ({ sn: generateId(), date: new Date(), status: STATUSES[Math.round(Math.random() * 3)] }));

function DevicesPage() {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} alignContent='flex-start'>
      <Grid item xs={12}>
        <Card>
          <CardHeader title={t('Devices')} />
          <CardContent>
            <List dense sx={{ width: '100%' }}>
              {DEVICES.map(({ sn, status, date }) => {
                return (
                  <ListItem disablePadding key={sn} >
                    <ListItemButton>
                      <Stack spacing={5} direction='row' alignItems='center'>
                        <ListItemText primary={sn} secondary={date.toISOString()} />
                        <Chip color={status === 'LIVE' ? 'primary' : status === 'MAINTENANCE' ? 'secondary' : undefined} label={status} />
                      </Stack>
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default DevicesPage;
