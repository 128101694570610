import React from 'react';
import { useTranslation } from 'react-i18next';

import HomeIcon from '@mui/icons-material/Home';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import { Divider, List } from '@mui/material';
import Sidebar, { SidebarElement } from '../components/misc/Sidebar';


export default function AppSidebar({ pathname }) {
  const { t } = useTranslation();

  return (
    <Sidebar>
      <List>
        <SidebarElement href={`${pathname}`} icon={<HomeIcon />} text={t('Home')} />
        <SidebarElement href={`${pathname}/products`} icon={<AccountTreeIcon />} text={t('Products')} />
        <SidebarElement href={`${pathname}/members`} icon={<PeopleIcon />} text={t('Members')} />
        <SidebarElement href={`${pathname}/settings`} icon={<SettingsIcon />} text={t('Settings')} />
      </List>
      <Divider />
    </Sidebar>
  )
}
