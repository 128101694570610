import React from 'react';

import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import AppPage from './pages/App/AppPage';
import AuthPage from './pages/AuthPage';
import MembersPage from './pages/App/MembersPage';
import RootPage from './pages/RootPage';
import SettingsPage from './pages/App/SettingsPage';
import TasksPage from './pages/Products/TasksPage';
import AppPageWrapper from './pages/App/AppPageWrapper';
import DevicesPage from './pages/Products/DevicesPage';
import TestbenchsPage from './pages/Products/TestbenchsPage';
import ProductsPageWrapper from './pages/Products/ProductsPageWrapper';
import ProductPage from './pages/Products/ProductPage';
import ProductsListPage from './pages/Products/ProductsListPage';
import OrganizationsWrapper from './pages/Organizations/OrganizationsWrapper';
import OrganizationPage from './pages/Organizations/OrganizationPage';
import OrganizationChooserPage from './pages/Organizations/OrganizationChooserPage';
import OrganizationPageWrapper from './pages/Organizations/OrganizationPageWrapper';
import { buildBreadcrumb } from './components/misc/Breadcrumbs';
import { buildSidebar } from './components/misc/Sidebar';
import OrganizationsSidebar from './components/orgs/OrganizationsSidebar';
import AppSidebar from './app/AppSidebar';
import ProductsSidebar from './components/products/ProductsSidebar';
import AssetsPage from './pages/Products/AssetsPage';
import ProductSettingsPage from './pages/Products/ProductSettingsPage';

const router = createBrowserRouter([
    {
        path: "/",
        element: <RootPage />,
        children: [
            {
                path: "auth",
                element: <AuthPage />,
            },
            {
                path: "org",
                element: <OrganizationsWrapper />,
                handle: {
                    ...buildBreadcrumb((match) => ({ text: 'Org' })),
                },
                children: [
                    {
                        index: true,
                        element: <OrganizationChooserPage />
                    },
                    {
                        path: ":organization_id",
                        element: <OrganizationPageWrapper />,
                        handle: {
                            ...buildSidebar((match) => <OrganizationsSidebar pathname={match.pathname} />),
                            ...buildBreadcrumb((match) => ({ text: match.params.organization_id })),
                        },
                        children: [
                            {
                                index: true,
                                element: <OrganizationPage />
                            },
                            {
                                path: "app",
                                element: <AppPageWrapper />,
                                handle: {
                                    ...buildSidebar((match) => <AppSidebar pathname={match.pathname} />),
                                    ...buildBreadcrumb(() => ({ text: 'App' })),
                                },
                                children: [
                                    {
                                        index: true,
                                        element: <AppPage />
                                    },
                                    {
                                        path: "products",
                                        element: <ProductsListPage />,
                                        handle: {
                                            ...buildBreadcrumb(() => ({ text: 'Products' })),
                                        },
                                    },
                                    {
                                        path: "settings",
                                        element: <SettingsPage />,
                                        handle: {
                                            ...buildBreadcrumb(() => ({ text: 'Settings' })),
                                        },
                                    },
                                    {
                                        path: "members",
                                        element: <MembersPage />,
                                        handle: {
                                            ...buildBreadcrumb(() => ({ text: 'Members' })),
                                        },
                                    },
                                ]
                            },
                            {
                                // products chooser page
                                path: "products/:product_id",
                                element: <ProductsPageWrapper />,
                                handle: {
                                    ...buildSidebar((match) => <ProductsSidebar pathname={match.pathname} />),
                                    ...buildBreadcrumb(() => ({ text: 'Products' })),
                                },
                                children: [
                                    {
                                        index: true,
                                        element: <ProductPage />,
                                        handle: {
                                            ...buildBreadcrumb((match) => ({ text: match.params.product_id })),
                                        },

                                    },
                                    {
                                        path: "devices",
                                        element: <DevicesPage />,
                                        handle: {
                                            ...buildBreadcrumb(() => ({ text: 'Devices' })),
                                        },
                                    },
                                    {
                                        path: "tasks",
                                        element: <TasksPage />,
                                        handle: {
                                            ...buildBreadcrumb(() => ({ text: 'Tasks' })),
                                        },
                                    },
                                    {
                                        path: "benchs",
                                        element: <TestbenchsPage />,
                                        handle: {
                                            ...buildBreadcrumb(() => ({ text: 'Testbenchs' })),
                                        },
                                    },
                                    {
                                        path: "assets",
                                        element: <AssetsPage />,
                                        handle: {
                                            ...buildBreadcrumb(() => ({ text: 'Assets' })),
                                        },
                                    },
                                    {
                                        path: "settings",
                                        element: <ProductSettingsPage />,
                                        handle: {
                                            ...buildBreadcrumb(() => ({ text: 'Assets' })),
                                        },
                                    },
                                ]
                            },
                        ]
                    },
                ]
            },
        ],
    },
]);

function AppRouter() {
    return (
        <RouterProvider router={router} />
    );
}

export default AppRouter;
