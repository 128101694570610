import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import AppBarMUI from '@mui/material/AppBar';
import { Typography, Toolbar, Stack, useTheme, Box } from '@mui/material';
import Breadcrumbs from './Breadcrumbs';
import { useTranslation } from 'react-i18next';
import ColorModeButton from './ColorModeButton';
import { SidebarInApp } from './Sidebar';


export default function AppBar({ children, padding = 4, sidebar = null, actions = null }) {

    const navigate = useNavigate();
    const theme = useTheme();
    const { t } = useTranslation();

    const handleClick = (location) => {
        navigate('/');
    }

    return (
        <Fragment>
            <AppBarMUI sx={{ zIndex: theme.zIndex.drawer + 1, }} position="fixed" enableColorOnDark>
                <Toolbar>
                    <Typography variant="h6" component="div" onClick={handleClick}>
                        {t('TaskLoop')}
                    </Typography>
                    <Box flexGrow={1}></Box>
                    <Stack spacing={2}>
                        {actions}
                        <ColorModeButton />
                    </Stack>
                </Toolbar>
            </AppBarMUI>


            <Stack flexGrow={1} flexDirection='row'>
                <SidebarInApp />
                <Stack flexGrow={1} flexDirection='column' sx={{ px: 4 }}>
                    <Box my={0} sx={{ ...theme.mixins.toolbar }} ></Box>
                    <Breadcrumbs />
                    <Box flexGrow={1} display='flex'>
                        {children}
                    </Box>
                </Stack>
            </Stack>
        </Fragment>

    );
}