import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Card, CardContent, } from '@mui/material';
import { useParams } from 'react-router-dom';

function OrganizationPage() {
  const { t } = useTranslation();

  const { organization_id } = useParams();

  return (
    <Grid container spacing={2} alignContent='flex-start'>
      <Grid item xs={12}>
        <Typography variant='h5'>{t('Organization')} {organization_id}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Card variant='outlined'>
          <CardContent>

          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default OrganizationPage;
