import React, { createContext, useContext, useState } from 'react';
import { useMatch, useMatches, useNavigate } from 'react-router-dom';
import { Divider, Box, Drawer, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, useTheme, Stack, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export const buildSidebar = (constructor) => {
  return { sidebar: constructor };
}

export const useSidebar = () => {
  let matches = useMatches();
  let sidebars = matches
    // get rid of any matches that don't have handle and sidebar
    .filter((match) => Boolean(match.handle?.sidebar));

  const match = sidebars[sidebars.length - 1];
  const sidebar = match?.handle.sidebar(match) || null;
  return sidebar;
};

export const SidebarInApp = () => {
  const sidebar = useSidebar();
  return sidebar;
}

const LocalSidebarContext = createContext({
  isOpen: false,
  open: () => console.error('early call to SidebarContext.open()'),
  close: () => console.error('early call to SidebarContext.close()')
});

export const useLocalSidebarContext = () => useContext(LocalSidebarContext);

const drawerWidth = 200;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

export function SidebarElement({ text, href, icon }) {

  const { isOpen } = useLocalSidebarContext();
  const navigate = useNavigate();
  const match = useMatch(href);

  const handleClick = (ev) => {
    if (href) {
      navigate(href);
    }
  }

  return (
    <ListItem disablePadding sx={{ display: 'block' }}>
      <ListItemButton selected={Boolean(match)} onClick={href ? handleClick : undefined} sx={{ minHeight: 48, justifyContent: isOpen ? 'initial' : 'center', px: 2.5, }}>
        {icon && <ListItemIcon sx={{ minWidth: 0, mr: isOpen ? 3 : 'auto', justifyContent: 'center' }} >
          {icon}
        </ListItemIcon>}
        <ListItemText primary={text} sx={{ opacity: isOpen ? 1 : 0 }} />
      </ListItemButton>
    </ListItem>
  )
}

export default function Sidebar({ children, defaultOpen = false, alwaysOpen = true }) {

  const [open, setOpen] = useState(defaultOpen || alwaysOpen);
  const theme = useTheme();

  const toggleDrawer = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    setOpen(opn => !opn);
  }

  const style = open ? {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  } : {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }

  return (
    <Drawer sx={{
      ...style, flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
    }} variant="permanent" open={open}>
      <Box sx={{ ...theme.mixins.toolbar }} ></Box>
      {!alwaysOpen && <Box textAlign={'right'} paddingRight={1}>
        <IconButton onClick={toggleDrawer}>
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </Box>}
      <Divider />
      <LocalSidebarContext.Provider value={{ isOpen: open, open: () => setOpen(true), close: () => setOpen(false) }}>
        {children}
      </LocalSidebarContext.Provider>
      <Box sx={{ flexGrow: 1 }}></Box>
      <Box sx={{ py: 1 }}>
        <Stack direction='row' spacing={2} justifyContent='center' alignItems='center'>
          <Typography variant='body2' fontSize={12}>{process.env.REACT_APP_VERSION}</Typography>
        </Stack>
      </Box>
    </Drawer>
  );
}