import React from 'react';
import { Breadcrumbs as BreadcrumbsMUI, Link, Box } from '@mui/material';
import _ from 'lodash';
import { useMatches, useNavigate } from 'react-router-dom';

export const buildBreadcrumb = (constructor) => {
    return { breadcrumb: constructor };
}

export const useBreadcrumbs = () => {
    let matches = useMatches();
    let breadcrumbs = matches
        // first get rid of any matches that don't have handle and crumb
        .filter((match) => Boolean(match.handle?.breadcrumb))
        // now map them into an array of elements, passing the loader
        // data to each one
        .map((match) => {
            const crumb = match.handle.breadcrumb(match);
            crumb.href = crumb.href || match.pathname;
            return crumb;
        });
    return breadcrumbs;
};

export default function Breadcrumbs() {
    const breadcrumbs = useBreadcrumbs();
    const navigate = useNavigate();

    return (
        <Box my={2} width='100%' role="presentation">
            <BreadcrumbsMUI aria-label="breadcrumb">
                {_.map(breadcrumbs, (breadcrumb = {}, index) => {
                    const { href, text, color = 'inherit', underline = 'hover', current = false } = breadcrumb;
                    return (
                        <Link
                            key={`${text}-${href}-${index}`}
                            underline={underline}
                            color={color}
                            href={href}
                            aria-current={current ? "page" : undefined}
                            onClick={(ev) => {
                                ev.preventDefault();
                                ev.stopPropagation();
                                navigate(href);
                            }}
                        >
                            {text}
                        </Link>
                    )
                })}
            </BreadcrumbsMUI>
        </Box>
    )
}
