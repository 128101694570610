import React from 'react';
import Sidebar, { SidebarElement } from '../misc/Sidebar';
import { useTranslation } from 'react-i18next';

import HomeIcon from '@mui/icons-material/Home';

import { Divider, List } from '@mui/material';

function OrganizationsSidebar({ pathname }) {
  const { t } = useTranslation();

  return (
    <Sidebar>
      <List>
        <SidebarElement href={`${pathname}/app`} icon={<HomeIcon />} text={t('Dashboard')} />
        <Divider />
      </List>
    </Sidebar>
  )
}

export default OrganizationsSidebar;
