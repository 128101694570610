import React from 'react';
import { Outlet } from 'react-router-dom';
import AppBar from '../../components/misc/AppBar';

function OrganizationsWrapper() {
  return (
    <AppBar>
      <Outlet />
    </AppBar>
  )
}

export default OrganizationsWrapper;
