import React, { useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate, useOutlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function RootPageInternal() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    navigate('org/taskloop/app');
  }, [navigate]);

  return (
    <Box flexGrow={1} display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
      <Typography>{t('Welcome on TaskLoop')}</Typography>
      <Button onClick={() => {
        navigate('org/taskloop')
      }}>{t('Go to App')}</Button>
    </Box>
  );
}

function RootPage() {

  const outlet = useOutlet();

  if (outlet) {
    return outlet;
  } else {
    return <RootPageInternal />
  }

}

export default RootPage;
