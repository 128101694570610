import React from 'react';
import { Outlet } from 'react-router-dom';

function OrganizationPageWrapper() {

  return (
    <Outlet />
  )
}

export default OrganizationPageWrapper;
