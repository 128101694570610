import React from 'react';
import { useTranslation } from 'react-i18next';
import { List, ListItem, ListItemButton, ListItemText, ListItemAvatar, Avatar, Grid, Typography, Card, CardContent, Button, } from '@mui/material';
import { names, uniqueNamesGenerator } from 'unique-names-generator';

const members = Array(5).fill({}).map(() => {
  const name = uniqueNamesGenerator({ dictionaries: [names] });
  return { name, mail: `${name.toLowerCase()}@taskloop.fr` }
});

function MembersPage() {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} alignContent='flex-start'>
      <Grid item xs={12}>
        <Typography variant='h5'>{t('Members')} </Typography>
      </Grid>
      <Grid item xs={12}>
        <Card variant='outlined'>
          <CardContent>
            <List sx={{ width: '100%' }}>
              {members.map(({ name, mail }) => {
                const labelId = `list-secondary-label-${name}`;
                return (
                  <ListItem
                    key={mail}
                  >
                    <ListItemButton>
                      <ListItemAvatar>
                        <Avatar>{name[0]}</Avatar>
                      </ListItemAvatar>
                      <ListItemText id={labelId} primary={mail} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} display='flex' justifyContent='center'>
        <Button>Add a member</Button>
      </Grid>
    </Grid>

  )
}

export default MembersPage;
