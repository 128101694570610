import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardContent, Grid, Typography } from '@mui/material';

function SettingsPage() {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} alignContent='flex-start'>
      <Grid item xs={12}>
        <Typography variant='h5'>{t('Settings')} </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h6'>{t('API Keys')} </Typography>
      </Grid>
      <Grid item xs={12}>
        <Card variant='outlined'>
          <CardContent>
            
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='h6'>{t('Language')} </Typography>
      </Grid>
      <Grid item xs={12}>
        <Card variant='outlined'>
          <CardContent>
            
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default SettingsPage;
