import React from 'react';
import { SnackbarProvider } from 'notistack';
import AppRouter from '../router';
import ThemeProvider from './theme-provider';

function App() {
  return (
    <ThemeProvider>
      <SnackbarProvider>
        <AppRouter />
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
