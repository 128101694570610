import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, List, ListItem, ListItemButton, Stack, ListItemText, } from '@mui/material';
import { useNavigate } from 'react-router-dom';
const { uniqueNamesGenerator, starWars } = require('unique-names-generator');

const PRODUCTS = Array(3).fill({}).map(() => ({ name: uniqueNamesGenerator({ dictionaries: [starWars] }), date: new Date() }));

function ProductsListPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Grid container spacing={2} alignContent='flex-start'>
      <Grid item xs={12}>
        <Typography variant='h5'>{t('Products')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <List dense sx={{ width: '100%' }}>
          {PRODUCTS.map(({ name, date }) => {
            return (
              <ListItem disablePadding key={name} >
                <ListItemButton onClick={(ev) => {
                  ev.preventDefault();
                  ev.stopPropagation();
                  navigate('../../products/' + name);
                }}>
                  <Stack spacing={5} direction='row' alignItems='center'>
                    <ListItemText primary={name} secondary={date.toISOString()} />
                  </Stack>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Grid>
    </Grid>
  )
}

export default ProductsListPage;
